<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Collecte d'informations") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row
          v-if="isFormActive"
          class="pt-5"
        >
          <v-col cols="12">
            <div class="d-flex align-center" v-if="item.author && item.author.id">
              <v-avatar
                :color="item.author.avatar ? '' : 'primary'"
                :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="30"
              >
                <v-img
                  v-if="item.author.avatar"
                  :lazy-src="require(`@/assets/images/avatars/2.png`)"
                  :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(item.author.full_name) }}</span>
              </v-avatar>

              <div class="d-flex flex-column ms-3">
                <router-link
                  :to="{ name : 'author-view', params : { id : item.author_id } }"
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.author.full_name }}
                </router-link>
                <small v-if="item.manuscript && item.manuscript.id">{{ item.manuscript.title }}</small>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            {{ item.info }}
          </v-col>
          <!--          <v-col
            v-if="item.is_quality"
            cols="12"
          >
            <v-label>{{ $t("Qualité du texte") }}</v-label>
            <v-radio-group
              v-model="item.is_quality_text"
              row
            >
              <v-radio
                :label="$t('TQ')"
                :value="1"
              ></v-radio>
              <v-radio
                :label="$t('TP')"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </v-col>-->
          <v-col
            v-if="item.column_type === 'text'"
            cols="12"
          >
            <v-text-field
              v-model="item.activity_date"
              :label="item.activity_date_label"
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col
            v-if="item.column_type === 'select'"
            cols="12"
          >
            <v-select
              v-model="item.activity_date"
              :label="item.activity_date_label"
              :items="item.items"
              :item-text="item.selectLabel"
              :item-value="item.selectId"
              clearable
              outlined
              dense
            ></v-select>
          </v-col>
          <!--          <v-col
            v-if="item.action == 'collecte_file_blocked'"
            cols="12"
          >
            <v-select
              v-model="item.activity_date"
              :label="$t('Status dossier')"
              :items="blocked"
              item-text="label"
              item-value="id"
              clearable
              outlined
              dense
              hide-details="auto"
            >
            </v-select>
          </v-col>-->
          <v-col
            v-if="item.action == 'signature'"
            cols="12"
          >
            <v-select
              v-model="item.status_id"
              :label="$t('Status')"
              :rules="[validators.required]"
              :error-messages="errorMessages.status_id"
              :items="$store.state.app.statuses.filter(ele => ele.status_type === 'publication')"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-select>
          </v-col>
          <v-col
            v-if="item.column_type != 'text' && item.column_type != 'select'"
            cols="12"
          >
            <date-picker
              v-if="isFormActive"
              v-model="item.activity_date"
              :max-date="maxDate"
              :label="$t('Date de traitement')"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
            <v-autocomplete
              v-if="item.action === 'paiement_author_copy_order'"
              v-model="item.color_id"
              :label="$t('Couleur de fond')"
              :error-messages="errorMessages.color"
              :items="$store.state['app'].colors.filter(ele => $store.state.app.settings.author_copy_order_colors_ids.includes(ele.id) )"
              item-text="color_name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
              <template #item="{ item }">
                <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                  <v-list-item-content>
                    <v-list-item-title>
<!--                      {{ item.color_name }}-->&nbsp;&nbsp;
                    </v-list-item-title>
                  </v-list-item-content>
                </v-item>
              </template>
              <template #selection="{ item }">
                <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }" style="padding: 0px 4px;">
                  <v-list-item-content>
                    <v-list-item-title>
<!--                      {{ item.color_name }}-->&nbsp;&nbsp;
                    </v-list-item-title>
                  </v-list-item-content>
                </v-item>
              </template>
            </v-autocomplete>
            <template v-else-if="item.date_column === 'visual_idea'">
              <v-autocomplete
                v-model="item.fore_color_id"
                :label="$t('Couleur du texte')"
                :error-messages="errorMessages.color"
                :items="$store.state['app'].colors.filter(ele => $store.state.app.settings.visual_idea_colors_ids.includes(ele.id))"
                item-text="color_name"
                item-value="id"
                outlined
                dense

                clearable
              >
              </v-autocomplete>
              <v-autocomplete
                v-if="item.fore_color_id === ($store.state.app.settings.white_color_id || 5)"
                v-model="item.color_id"
                :label="$t('Couleur de fond')"
                :error-messages="errorMessages.color"
                :items="$store.state['app'].colors.filter(ele => ele.id === ($store.state.app.settings.green_color_id || 1))"
                item-text="color_name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                clearable
              >
                <template #item="{ item }">
                  <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.color_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-item>
                </template>
                <template #selection="{ item }">
                  <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }" style="padding: 0px 4px;">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.color_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-item>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-else
                v-model="item.color_id"
                :label="$t('Couleur de fond')"
                :error-messages="errorMessages.color"
                :items="$store.state['app'].colors.filter(ele =>  !$store.state.app.settings.visual_idea_colors_ids.includes(ele.id))" item-text="color_name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                clearable
              >
                <template #item="{ item }">
                  <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                    <v-list-item-content>
                      <v-list-item-title>
{{ item.color_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-item>
                </template>
                <template #selection="{ item }">
                  <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }" style="padding: 0px 4px;">
                    <v-list-item-content>
                      <v-list-item-title>
{{ item.color_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-item>
                </template>
              </v-autocomplete>
            </template>
            <v-autocomplete
              v-else
              v-model="item.color_id"
              :label="$t('Couleur de fond')"
              :error-messages="errorMessages.color"
              :items="$store.state['app'].colors.filter(ele => !$store.state.app.settings.visual_idea_colors_ids.includes(ele.id))"
              item-text="color_name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
              <template #item="{ item }">
                <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                  <v-list-item-content>
                    <v-list-item-title>
{{ item.color_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-item>
              </template>
              <template #selection="{ item }">
                <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }" style="padding: 0px 4px;">
                  <v-list-item-content>
                    <v-list-item-title>
{{ item.color_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="item.comment"
              outlined
              rows="2"
              auto-grow
              dense
              :label="$t('Commentaire')"
              :placeholder="$t('Commentaire')"
              hide-details="auto"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Confirm") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker, AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
      items,
    } = controller()
    const {
    } = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalDate = ref(false)
    const dialogDate = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const blocked = ref([
      { id: 'DD', label: 'Dossier décalé' },
      { id: 'Pmt', label: 'Paiement' },
      { id: 'NS', label: 'No signé' },
      { id: 'FW', label: 'Fichier Word' },

      /* { id: "vide", label: 'Ouvert' }, */
    ])
    const maxDate = ref((new Date(Date.now())).toISOString().substr(0, 10))
    const validate = () => {
      form.value.validate()
    }
    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })


    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        const payload = { id: item.value.id, comment: item.value.comment, color_id: item.value.color_id || null, fore_color_id: item.value.fore_color_id || null }
        payload[item.value.date_column] = item.value.activity_date

        /* if (item.value.is_quality) {
          payload.is_quality_text = item.value.is_quality_text
        } */
        if (item.value.action === 'signature') {
          payload.status_id = item.value.status_id
        }

        axios.post(`/publications/action/${item.value.action}/${item.value.id}`,
          payload)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            // emit('update:is-form-active', false)
            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    const setColor = () => {

      if (item.value.action && item.value.publication_colors && item.value.publication_colors.length > 0 && item.value.publication_colors.filter(ele => ele.colomn_name === item.value.date_column).length > 0) {
        const records = item.value.publication_colors.filter(ele => ele.colomn_name === item.value.date_column)

        item.value.color_id = records[0].color_id || null
        item.value.fore_color_id = records[0].fore_color_id || null
        return false
      }
      if (item.value.action && item.value.publication_records && item.value.publication_records.length > 0 && item.value.publication_records.filter(ele => ele.type === item.value.action).length > 0) {
        const records = item.value.publication_records.filter(ele => ele.type === item.value.action).sort((a, b) => b.id - a.id)

        item.value.color_id = records[0].color_id || null
      }

    }
    setColor()
    watch(item, () => {
      setColor()
    }, {
      deep: true,
    })

    return {
      form,
      maxDate,
      blocked,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      isLoadingAuthors,
      onSubmit,
      modalDate,
      dialogDate,
      item,
      valid,
      dialog,
      loading,
      validate,
      avatarText,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
