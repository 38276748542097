<template>
  <v-row class="author-bio-panel">
    <!-- author profile -->
    <v-col
      cols="12"
      md="3"
      lg="3"
      class="text-center"
    >
      <v-card
        class="pt-10 pb-4"
        style="height: 100%"
      >
        <v-avatar
          :color="author.avatar ? '' : 'primary'"
          :class="author.avatar ? '' : 'v-avatar-light-bg primary--text'"
          width="150"
          height="150"
          rounded
          class="mb-4"
        >
          <v-img
            v-if="author.avatar"
            :lazy-src="require(`@/assets/images/avatars/1.png`)"
            :src="author.avatar || require(`@/assets/images/avatars/1.png`)"
            height="150"
            contain
          ></v-img>
          <span
            v-else
            class="font-weight-semibold text-5xl"
          >{{ avatarText(author.full_name) }}</span>
        </v-avatar>

        <!--        <div class="font-weight-bold text-xl">
                  {{ author.full_name }}
        &lt;!&ndash;          #{{ author.reference || author.id }}&ndash;&gt;
                </div>-->
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="9"
      lg="9"
    >
      <v-card
        style="height: 100%"
        class=""
      >
        <v-card-title>{{ $t("Informations de") }} {{ author.full_name }}
          <v-btn icon small @click="openDialog(author)">
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text--primary">
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ $t("Name") }} :</span>
              <div class="text--primary font-weight-semibold">
                {{ author.full_name }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ $t("E-mail") }} :</span>
              <div class="text--primary font-weight-semibold">
                {{ author.email?author.email.join(', '):'' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ $t("Téléphone") }} :</span>
              <div class="text--primary font-weight-semibold">
                {{ formatPhoneNumber(author) }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ $t("Source") }} :</span>
              <div class="text--primary font-weight-semibold">
                {{ author.source ? author.source.source_name : '-' }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ $t("Status") }} :</span>
              <div class="text--primary font-weight-semibold">
                <v-chip
                  small
                  :color="author.status? author.status.color: ''"
                >
                  {{ author.status ? author.status.name : '-' }}
                </v-chip>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ $t("Référence") }} :</span>
              <div class="text--primary font-weight-semibold">
                {{ author.author_ref }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ $t("Compte auteur") }} :</span>
              <div class="text--primary font-weight-semibold">
                {{ author.compte_auteur }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-dialog
          v-model="isFormActive"
          touchless
          :right="!$vuetify.rtl"
          :width="$vuetify.breakpoint.smAndUp ? 600 : '100%'"
        >
          <author-form
            v-model="author"
            :is-form-active="isFormActive"
            :pay-options="payOptions"
            :with-addresses="false"
            :plan-options="planOptions"
            @refetch-data="fetchAuthors"
          >
            <template v-slot:form-title>
              <span class="font-weight-semibold text-base text--primary">{{
                  author.id ? $t('Update') : $t('Ajouter un auteur')
                }}</span>
              <v-spacer/>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isFormActive = !isFormActive"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </template>
            <template #form-cancel>
              <v-btn
                :loading="loading"
                color="secondary"
                outlined
                type="reset"
                @click="isFormActive = !isFormActive"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </template>
          </author-form>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiPencil,
} from '@mdi/js'
import {avatarText} from '@core/utils/filter'
import {ref} from '@vue/composition-api'
import controller from '../../author-list/useAuthorsList'
import AuthorForm from "@/views/apps/author/author-list/AuthorForm";
import store from "@/store";

export default {
  components: {
    AuthorForm
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      downloadObject,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      authorTotalLocal,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchAuthors,
      resolveAuthorRoleIcon,
      resolveAuthorTotalIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorRoleVariant
    } = controller()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Authors', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    const superiors = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Active', value: 1 },
      { title: 'Inactive', value: 0 },
    ]

    const formatPhoneNumber = val => {
      if (val.country_id == (store.state.app.settings.default_country_id || 79)) {
        // let p = val.phone
        // var string = "02076861111"
        let string = val.phone
        if (string && string.length < 9) {
          string = `0${string}`
        }
        if(string){
          if(string.length > 7 && string.length < 10){
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4')
            return phone
          }else{
            if(string.length >9){
              const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4.$5')
              return phone
            }
          }
          const phone = string.replace(/(\d{2})(\d{2})(\d{7})/, '$1.$2.$3')
          return phone
        }
        return val.phone
      }
      return val.phone
    }

    return {
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      authorTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      downloadObject,
      avatarText,
      resolveAuthorRoleVariant,
      resolveAuthorRoleIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorTotalIcon,
      fetchAuthors,
      resolveCurrentPlanValue,
      formatPhoneNumber,
      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiPencil,
      },
    }
  },
}
</script>
