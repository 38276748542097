<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Accord Signature") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row class="pt-5">
          <!--          <v-col cols="12">
            {{ $t('Voulez-vous marquer ce dossier') }} <strong>'{{ item.title }}'</strong> {{ $t('comme étant signé ?') }}
          </v-col>-->
          <v-col
            cols="12"
          >
            <v-select
              v-model="item.status_id"
              :label="$t('Status')"
              :rules="[validators.required]"
              :error-messages="errorMessages.status_id"
              :items="$store.state['app-publication'].statuses"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
          <v-spacer />
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'

export default {
  components: { AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
      accordDialog,
    } = controller()
    const {
    } = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalReceipt = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)
    const newAuthor = () => {
      item.value.author = {
        full_name: search.value, email: null, phone: null, addresses: [],
      }
      isFormAuthorActive.value = true
    }
    const searchAuthors = term => {
      store.dispatch('app/searchAuthors', { term, rowsPerPage: 100 }).then(response => {
        isLoadingAuthors.value = false
        authors.value = response.data.data
      })
    }
    axios.get('/fees').then(response => {
      fees.value = response.data.data
    })
    const updateAuthors = author => {
      authors.value.push(author)
      item.value.author_id = author.id
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('app-publication/actionOnItem', { path: `/publications/action/accept_signature/${item.value.id}`, item: { id: item.value.id, status_id: item.value.status_id } })
          .then(response => {
            console.log('pass world')
            loading.value = false
            accordDialog.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)
            dialog.value = false
            emit('refetch-data', { type: 'accord' })
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    searchAuthors('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingAuthors.value) return ''
      isLoadingAuthors.value = true
      searchAuthors(val)

      return ''
    })
    const calculatePrice = () => {
      const fee = fees.value.find(i => i.page_min <= item.value.sum_pages && i.page_max >= item.value.sum_pages)

      if (fee && (item.value.sum_pages)) {
        item.value.public_price = parseFloat(fee.frais)/* + (parseFloat(item.value.sum_pages - fee.subtraction) * fee.percentage).toFixed(2) */
        console.log(item.value.public_price)
      } else {
        item.value.public_price = store.state.app.settings.default_pages_price || 27
      }
    }
    const calculatePages = () => {
      // item.value.sum_pages = null
      if (!item.value.custom_pages && item.value.sum_words >= 0) {
        const words = store.state.app.settings.number_words_per_page || 260
        item.value.sum_pages = Math.ceil(item.value.sum_words / words)
      } else {
        console.log('test')
        item.value.sum_pages = null
      }
    }
    const nbPages = computed({
      get: () => item.value.sum_pages,

      /* set: value => emit('update:object', value), */
    })
    const nbWords = computed({
      get: () => item.value.sum_words,
    })
    watch(nbPages, val => {
      calculatePrice()

      return ''
    })
    watch(nbWords, val => {
      calculatePages()

      return ''
    })

    store
      .dispatch('app/fetchSources', { per_page: 500 })

    return {
      resetItem,
      form,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      isLoadingAuthors,
      onSubmit,
      updateAuthors,
      modalReceipt,
      formatDate,
      newAuthor,
      accordDialog,
      item,
      valid,
      dialog,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
